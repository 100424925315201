<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑关键词回复' : '创建关键词回复'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 关键词 -->
      <a-form-item
        label="关键词"
        name="keyword"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入关键词"
          v-model:value="formState.keyword"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 消息类型 -->
      <a-form-item
        label="消息类型"
        name="message_type"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.message_type" @change="changePushMsgType">
          <a-radio :value="item.value" v-for="(item, index) in PushMsgTypes">{{ item.text }}</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 内容 - H5链接 -->
      <a-form-item
        v-if="[1].includes(formState.message_type)"
        label="内容"
        :name="['message_content', 'url_type']"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.message_content.url_type">
          <a-radio :value="1">短剧链接</a-radio>
          <a-radio :value="2">自定义链接</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 内容 - 微信小程序 -->
      <a-form-item
        v-if="[2].includes(formState.message_type)"
        label="内容"
        :name="['message_content', 'url_type']"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.message_content.url_type">
          <a-radio :value="1">播放页</a-radio>
          <a-radio :value="2">其他</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 短剧链接 -->
      <a-form-item
        v-if="[1, 2].includes(formState.message_type) && [1].includes(formState.message_content.url_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'project_drama_id']"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <!-- 选择短剧 -->
        <a-select
          show-search
          placeholder="请选择短剧"
          v-model:value="formState.message_content.project_drama_id"
          style="width: 220px;"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in dramas"
            :key="`${item.project_drama_id}-${item.remark_name}`"
            :value="item.project_drama_id"
          >
            {{ item.remark_name }}
          </a-select-option>
        </a-select>
        <!-- 载入默认设定 -->
        <a-button style="margin-left: 12px;" @click="touchDefaultDrama">载入剧集默认设定</a-button>
      </a-form-item>
      <!-- 自定义链接 -->
      <a-form-item
        v-if="[1].includes(formState.message_type) && [2].includes(formState.message_content.url_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'url']"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入链接"
          v-model:value="formState.message_content.url"
        />
      </a-form-item>
      <!-- 路径 -->
      <a-form-item
        v-if="[2].includes(formState.message_type) && [2].includes(formState.message_content.url_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'url']"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入路径"
          v-model:value="formState.message_content.url"
        />
      </a-form-item>
      <!-- 标题 -->
      <a-form-item
        v-if="[1, 2].includes(formState.message_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'title']"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入标题"
          v-model:value="formState.message_content.title"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 描述 -->
      <a-form-item
        v-if="[1].includes(formState.message_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'desc']"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入描述"
          v-model:value="formState.message_content.desc"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 图片 - 图片 -->
      <a-form-item
        v-if="[4].includes(formState.message_type)"
        label="图片"
        :name="['message_content', 'covers']"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <!-- 上传组件 -->
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="PushMsgAdd"
          :fileNumber="1"
          :beforeUploadPro="beforeUploadPro"
          :fileList="formState.message_content.covers"
          :uploadBucket="$pub.BUCKET_PUB()"
        >
          <template #up-title>
            上传图片
          </template>
        </Upload>
        <div class="form-upload-hint">支持格式：.png、.jpg</div>
        <div class="form-upload-list" v-if="formState.message_content.covers.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.message_content.covers"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover(index)"
          >
          </ImageView>
        </div>
      </a-form-item>
      <!-- 图片 - H5链接 -->
      <a-form-item
        v-if="[1].includes(formState.message_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'covers']"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <!-- 上传组件 -->
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="PushMsgAdd"
          :fileNumber="1"
          :imgSizeMode="22"
          :imgSizeWidth="144"
          :imgSizeHeight="192"
          imgSizeError="图片最小尺寸为 144 * 192"
          :imgScaleMode="1"
          :imgScaleWidth="144"
          :imgScaleHeight="192"
          imgScaleError="图片尺寸比例不正确"
          :beforeUploadPro="beforeUploadPro"
          :fileList="formState.message_content.covers"
          :uploadBucket="$pub.BUCKET_PUB()"
        >
          <template #up-title>
            上传缩略图
          </template>
        </Upload>
        <div class="form-upload-hint">支持格式：.png、.jpg，尺寸 144x192</div>
        <div class="form-upload-list" v-if="formState.message_content.covers.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.message_content.covers"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover(index)"
          >
          </ImageView>
        </div>
      </a-form-item>
      <!-- 图片 - 微信小程序 -->
      <a-form-item
        v-if="[2].includes(formState.message_type)"
        class="form-hide-label"
        label=" "
        :name="['message_content', 'covers']"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <!-- 上传组件 -->
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="PushMsgAdd"
          :fileNumber="1"
          :imgSizeMode="22"
          :imgSizeWidth="624"
          :imgSizeHeight="840"
          imgSizeError="图片最小尺寸为 624 * 840"
          :imgScaleMode="1"
          :imgScaleWidth="624"
          :imgScaleHeight="840"
          imgScaleError="图片尺寸比例不正确"
          :beforeUploadPro="beforeUploadPro"
          :fileList="formState.message_content.covers"
          :uploadBucket="$pub.BUCKET_PUB()"
        >
          <template #up-title>
            上传缩略图
          </template>
        </Upload>
        <div class="form-upload-hint">支持格式：.png、.jpg，尺寸 624x840</div>
        <div class="form-upload-list" v-if="formState.message_content.covers.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.message_content.covers"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover(index)"
          >
          </ImageView>
        </div>
      </a-form-item>
      <!-- 选择客服 -->
      <a-form-item
        v-if="[5].includes(formState.message_type)"
        label="选择客服"
        :name="['message_content', 'userid']"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          show-search
          placeholder="请选择"
          v-model:value="formState.message_content.userid"
          :disabled="true"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="item in customerServices" :key="item.account" :value="item.account">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 前置引导消息 -->
      <a-form-item
        v-if="[1, 2, 4, 5].includes(formState.message_type)"
        label="前置引导消息"
        :name="['message_content', 'boot_message']"
      >
        <a-textarea
          placeholder="请输入"
          v-model:value="formState.message_content.boot_message"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="100"
        />
      </a-form-item>
      <!-- 文本内容 -->
      <a-form-item
        v-if="[3].includes(formState.message_type)"
        label="文本内容"
        :name="['message_content', 'text']"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-textarea
          placeholder="请输入"
          v-model:value="formState.message_content.text"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="100"
        />
      </a-form-item>
      <!-- 发送账号 -->
      <!-- <a-form-item
        label="发送账号"
        name="send_account"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          mode="multiple"
          placeholder="请选择"
          v-model:value="formState.send_account"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option key="1-Jack" value="1">Jack</a-select-option>
          <a-select-option key="2-Lucy" value="2">Lucy</a-select-option>
        </a-select>
      </a-form-item> -->
      <!-- 发送时间 -->
      <!-- <a-form-item
        label="发送时间"
        name="time"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-date-picker
          style="width: 100%;"
          show-time
          placeholder="请选择"
          v-model:value="formState.time"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        />
      </a-form-item> -->
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import ImageView from '@/components/ImageView'
import { messageSustomerSave, projectDramaAll, qyMessageDetail, wechatAccount } from '@/api/operate'
import Pub from '@/utils/public'
import { PushMsgTypes, CustomerService } from '@/utils/constantList'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 客服列表
let customerServices = ref([])
// id
let id = ref(null)
// 短剧列表
let dramas = ref([])
// 表单
let formState = reactive({
  // 关键词
  keyword: undefined,
  // 消息类型
  message_type: 1,
  // // 发送账号
  // send_account: [],
  // // 发送时间
  // time: undefined,
  // send_time: undefined,
  // 消息内容
  message_content: {
    // 内容类型
    url_type: 1,
    // 短剧ID
    project_drama_id: undefined,
    // 自定义链接 | 路径
    url: undefined,
    // 集数
    eq_number: 1,
    // 标题
    title: undefined,
    // 描述
    desc: undefined,
    // 封面
    covers: [],
    // 封面图片
    image: undefined,
    // 客服
    userid: 'JinQing',
    // 前置引导消息
    boot_message: undefined,
    // 文本内容
    text: undefined
  }
})

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 记录 ID
  if (params) { id.value = params.id }
  // 获取短剧列表
  getProjectDramaAll()
  // 获取选中列表
  // getSelectList(params)
  // 赋值
  nextTick(() => {
    // 有 ID 需要编辑
    if (id.value) {
      // 初始化
      formState.keyword = params.keyword
      formState.message_type = params.message_type
      formState.message_content = {
        ...formState.message_content,
        ...params.message_content,
        image: params.message_content[0]
      }
    }
  })
}

// 获取选中列表
function getSelectList (params) {
  wechatAccount().then(res => {
    const { code, data } = res
    if (code === 0) {
      params.send_account = sendAccounts
      formState.send_account = sendAccounts
      customerServices.value = list
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    formState.message_content.covers = []
    id.value = undefined
    visible.value = false
  }
}

// 短剧列表
function getProjectDramaAll () {
  projectDramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    // 检查上传
    let imgs = formState.message_content.covers || []
    let isUploadSuccess = imgs.every(item => {
      return item.status === undefined || item.status === 'done'
    })
    if (!isUploadSuccess) {
      message.error('正在上传中...')
      return
    }
    // isUploadSuccess = formState.message_content.message_list.every(item => {
    //   imgs = item.covers || []
    //   return imgs.every(item => { return item.status === undefined || item.status === 'done' })
    // })
    // if (!isUploadSuccess) {
    //   message.error('正在上传中...')
    //   return
    // }
    isLoading.value = true
    // 获取剧集对象
    const drama = dramas.value.find(item => item.project_drama_id === formState.message_content.project_drama_id)
    // 处理封面图片
    formState.message_content.image = formState.message_content.covers[0]
    // 组装上传参数
    const params = {
      // 用户id
      id: id.value,
      // 表单数据
      ...formState
    }
    messageSustomerSave(params).then(res => {
      isLoading.value = false
      const { code, msg } = res
      if (code === 0) {
        message.success('提交成功')
        // 更新父组件
        emit('success')
        onClose()
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      message.error('提交失败')
    })
  })
}

// 切换推送消息类型
function changePushMsgType () {
  // 清空缩略图，因为尺寸不一样
  formState.message_content.covers = []
  // 清空自定义链接 | 路径
  formState.message_content.url = undefined
}

// 载入默认剧集设定
function touchDefaultDrama () {
  // 是否有剧集id
  if (!formState.message_content.project_drama_id) {
    message.error('请先选择短剧')
    return
  }
  // 开始请求
  isLoading.value = true
  qyMessageDetail({ id: formState.message_content.project_drama_id }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 载入数据
      const info = data.qy_remark
      // 有配置
      if (info) {
        // 根据消息类型配置
        if (formState.message_type === 1) {
          // H5链接
          formState.message_content.title = info.h5_title
          formState.message_content.desc = info.h5_desc
          const cover = info.h5_cover.find(item => item.is_choose)
          formState.message_content.covers = [cover]
        } else if (formState.message_type === 2) {
          // 微信小程序
          formState.message_content.title = info.wxapp_title
          formState.message_content.desc = undefined
          const cover = info.wxapp_cover.find(item => item.is_choose)
          formState.message_content.covers = [cover]
        }
        // 提示
        message.success('载入成功')
      } else {
        // 提示
        message.error('未配置剧集默认设定')
      }
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    message.error('载入失败')
  })
}

// 准备上传
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 移除封面
function touchDeleteCover (index) {
  formState.message_content.covers.splice(index, 1)
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-hide-label /deep/ .ant-form-item-label {
  opacity: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
</style>