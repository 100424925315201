<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>关键词回复</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="touchAdd"><plus-outlined /> 新建</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchEdit(record)">编辑</a>
        <a class="operation-item" @click="touchEnable(record)" v-if="record.status === 1">停用</a>
        <a class="operation-item" style="color: #A5AEB5;" @click="touchEnable(record)" v-else>启用</a>
      </template>
    </template>
  </a-table>
  <!-- 新增 -->
  <Add ref="RefAdd" @success="getMessageSustomerList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Pub from '@/utils/public'
import { message } from 'ant-design-vue'
import { messageSustomerList, messageSustomerStatus } from '@/api/operate'
import Add from './components-keyword-reply/Add.vue'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '关键词ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '关键词',
    dataIndex: 'keyword',
    key: 'keyword'
  },
  {
    title: '创建人',
    dataIndex: 'create_username',
    key: 'create_username'
  },
  {
    title: '创建时间',
    dataIndex: 'created_time',
    key: 'created_time'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取注册用户列表
  getMessageSustomerList()
})

// 获取注册用户列表
function getMessageSustomerList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  messageSustomerList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getMessageSustomerList()
}

// 新建
function touchAdd () {
  RefAdd.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record || {})
}

// 停用/启用
function touchEnable(record) {
  isLoading.value = true
  const status = record.status === 1 ? 2 : 1
  const params = {
    id: record.id,
    status: status
  }
  messageSustomerStatus(params).then(res => {
    isLoading.value = false
    const { code } = res
    if (code === 0) {
      message.success(status === 1 ? '启用成功' : '停用成功')
      getMessageSustomerList()
    } else {
      message.error(res.message)
    }
  }).catch(() => {
    isLoading.value = false
    message.error(status === 1 ? '启用失败' : '停用失败')
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>